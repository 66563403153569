<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <h1 class="font-weight-bold mb-3">事業内容</h1>
      </v-col>
    </v-row>
    <v-flex xs12>
      <v-layout row wrap align-center>
        <v-flex xs12>
          <v-card class="card-area">
            <v-layout row>
              <v-flex xs6>
                <v-card-title primary-title class="card">
                  <div>
                    <div class="head">土木工事</div>
                    <ul>
                      <li>
                        <span>土地造成工事</span>
                      </li>
                      <li>
                        <span>河川工事</span>
                      </li>
                    </ul>
                  </div>
                </v-card-title>
              </v-flex>
              <v-flex xs6>
                <img
                  src="@/assets/images/works/construction.jpg"
                  alt="事業案内の土木工事についてのイメージ"
                />
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card class="card-area">
            <v-layout row>
              <v-flex xs6>
                <v-card-title primary-title class="card">
                  <div>
                    <div class="head">舗装工事</div>
                    <ul>
                      <li>
                        <span>アスファルト舗装</span>
                      </li>
                      <li>
                        <span>コンクリート舗装</span>
                      </li>
                    </ul>
                  </div>
                </v-card-title>
              </v-flex>
              <v-flex xs6>
                <img
                  src="@/assets/images/works/paving_work.jpg"
                  alt="事業案内の舗装工事についてのイメージ"
                />
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card class="card-area">
            <v-layout row>
              <v-flex xs6>
                <v-card-title primary-title class="card">
                  <div>
                    <div class="head">しゅんせつ工事</div>
                    <ul>
                      <li>
                        <span>しゅんせつ工事</span>
                      </li>
                    </ul>
                  </div>
                </v-card-title>
              </v-flex>
              <v-flex xs6>
                <img
                  src="@/assets/images/works/block.jpg"
                  alt="事業案内のしゅんせつ工事についてのイメージ"
                />
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-container>
</template>

<script>
export default {
  name: "Work",
  mounted() {
    document.title = "徳永工務店 | 事業内容";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "徳永工務店では建設業、建築工事、土木工事の対応を行っております。"
      );
  },
};
</script>

<style scoped>
.card-area {
  margin: 1rem;
}
.card {
  text-align: left;
}
.head {
  font-size: 20px !important;
  font-weight: bold;
  color: darkorange;
}
li {
  color: darkorange;
}
li span {
  color: #2c3e50;
  font-size: 16px;
}
img {
  width: 90%;
  height: auto;
  margin-top: 1rem;
}
</style>
